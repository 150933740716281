.container {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  padding-bottom: 10vw;
  /* padding-right: 2em;
  padding-right: 2em; */
}

.form{
  margin-left: auto;
  margin-right: auto;
}
.appImg {
  margin: auto;
  width: 20vw;
}




p, h1 {
  text-align: center;
}

p a{
  text-decoration: none;
}

.description {
  /* font-weight: 600; */
  padding: 1rem
}

h1{
  font-weight: 600;
}

/* .list{
  align-content: flex-start;
  display: list-item;
} */

.list li, ol{
  padding: 0.5rem;
}

.list{
  max-width: 90%;
}
/* .row {
  flex-direction: row;
} */

@media only screen and (max-width: 600px) {
  .appImg {
    width: 70vw;
  }
}

* {
  font-family: "Courier New", monospace;
  /* font-weight: 500; */
}
