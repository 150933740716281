.navBar {
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navList {
  list-style: none;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

a {
  /* padding: 1em; */
  color: #333;
  text-decoration: none;
}

.navList a:hover {
  color: #007bff;
}

.box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 8px;
  color: white;
  background-color: #007bff;
  border-radius: 12.5px;
  font-family: "Courier New", monospace;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.box:hover {
  opacity: 0.8;
}
