.logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
    /* padding-top: 1rem */
}

.text {
    padding: 8px;
    color: #333;
    font-family: 'Courier New', monospace;
    font-size: 18px;
    font-weight: 600;
}

.box {
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    padding: 8px;
    color: white;
    background-color: #007BFF;
    border-radius: 12.5px;
    font-family: 'Courier New', monospace;
    font-size: 18px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
}